.restaurant-menu-page-video-promo-background {
  position: fixed;
  /* On top of header */
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--full-screen-overlay-background-color);

  touch-action: none;
}

.restaurant-menu-page-video-promo {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 4px;
  right: 100%;
  width: 239px;
  max-width: 239px;
  padding-right: 9px;
  /* On top of background */
  z-index: 4;
  pointer-events: none;
}

.restaurant-menu-page-video-promo-icon {
  margin-left: auto;
}

.restaurant-menu-page-video-promo-title {
  color: var(--full-screen-overlay-text-color);
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: bold;
  padding-top: 13px;
  padding-right: 24px;
}

.restaurant-menu-page-video-promo-subtitle {
  color: var(--full-screen-overlay-text-color);
  font-size: 18px;
  padding-top: 9px;
  padding-right: 24px;
}

.restaurant-menu-page-video-promo-video-text {
  display: inline-block;
  position: relative;
}

.restaurant-menu-page-video-promo-underline {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}

.restaurant-menu-page-video-promo-highlight {
  position: absolute;
  left: calc(100% - 4px);
  bottom: calc(100% - 4px);
  /* On top of background */
  z-index: 4;
  pointer-events: none;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.fade-in-enter-done {
  opacity: 1;
}

.fade-in-exit {
  opacity: 1;
  pointer-events: none;
}

.fade-in-exit-active {
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms;
}

.fade-in-exit-done {
  opacity: 0;
  pointer-events: none;
}
