.restaurant-menu-item-row {
  position: relative;
  width: 100%;
  text-align: left;
  padding: 16px 0;
  display: flex;
  gap: 26px;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-item-row {
    padding: 16px;
    border: 1px solid var(--border-color);
    /* Ensure heights match each other in two-column layout. */
    height: 100%;
  }
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-item-row > *:not(:last-child) {
    margin-right: 26px;
  }
}

.restaurant-menu-item-row-button:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--border-color);
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-item-row-button:not(:last-child)::after {
    display: none;
  }
}

.restaurant-menu-item-row-button {
  position: relative;
}

.restaurant-menu-item-row-text-container {
  width: 100%;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
}

.restaurant-menu-item-row-title {
  font-size: 18px;
  font-weight: var(--header-weight);
  color: var(--item-title-color);
}

.restaurant-menu-item-row-subtitle-container {
  position: relative;
}

.restaurant-menu-item-row-subtitle-container-margin {
  /* Account for increased line-height. */
  margin-top: 9.2px;
  margin-bottom: -2.8px;
}

.restaurant-menu-item-row-subtitle {
  font-size: 14px;
  text-transform: lowercase;
  color: var(--item-description-color);
  line-height: 1.4;
}

.restaurant-menu-item-row-subtitle-line-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.restaurant-menu-item-row-price {
  font-size: 14px;
  font-weight: var(--price-weight);
  margin-top: 8px;
  color: var(--item-price-color);
}

.restaurant-menu-item-row-thumbnail-container {
  --thumbnail-width: 84px;
  position: relative;
  width: var(--thumbnail-width);
  height: calc(4 / 3 * var(--thumbnail-width) + 13px);
}

.restaurant-menu-item-row-thumbnail {
  width: var(--thumbnail-width);
  height: calc(4 / 3 * var(--thumbnail-width));
  border-radius: 4px;
  display: block;
  object-fit: cover;
}

/** 390px is the normal iPhone + larger. */
@media only screen and (min-width: 390px) {
  .restaurant-menu-item-row-thumbnail-container {
    --thumbnail-width: 88px;
  }
}

.restaurant-menu-item-video-icon-container {
  position: absolute;
  bottom: 0;
  right: 0;
}

.restaurant-menu-item-row-addons-text {
  color: var(--item-description-color);
  text-align: left;
  font-size: 12px;
  padding: 8px;
  margin-left: -8px;
  margin-bottom: -8px;
  text-decoration: underline;
}
