.fade-overlay {
  --fade-overlay-size: 60px;

  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.scroll-top-fade {
  top: 0;
  left: 0;
  right: 0;
  height: var(--fade-overlay-size);
}

.scroll-top-fade.fade-active {
  background: linear-gradient(
    to bottom,
    rgba(var(--background-color-rgb), 1) 0%,
    rgba(var(--background-color-rgb), 0) 100%
  );
}

.scroll-top-fade.fade-active.header {
  background: linear-gradient(
    to bottom,
    rgba(var(--header-background-color-rgb), 1) 0%,
    rgba(var(--header-background-color-rgb), 0) 100%
  );
}

.scroll-bottom-fade {
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--fade-overlay-size);
}

.scroll-bottom-fade.fade-active {
  background: linear-gradient(
    to top,
    rgba(var(--background-color-rgb), 1) 0%,
    rgba(var(--background-color-rgb), 0) 100%
  );
}

.scroll-bottom-fade.fade-active.header {
  background: linear-gradient(
    to top,
    rgba(var(--header-background-color-rgb), 1) 0%,
    rgba(var(--header-background-color-rgb), 0) 100%
  );
}

.scroll-left-fade {
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--fade-overlay-size);
}

.scroll-left-fade.fade-active {
  background: linear-gradient(
    to right,
    rgba(var(--background-color-rgb), 1) 0%,
    rgba(var(--background-color-rgb), 0) 100%
  );
}

.scroll-left-fade.fade-active.header {
  background: linear-gradient(
    to right,
    rgba(var(--header-background-color-rgb), 1) 0%,
    rgba(var(--header-background-color-rgb), 0) 100%
  );
}

.scroll-right-fade {
  right: 0;
  top: 0;
  bottom: 0;
  width: var(--fade-overlay-size);
}

.scroll-right-fade.fade-active {
  background: linear-gradient(
    to left,
    rgba(var(--background-color-rgb), 1) 0%,
    rgba(var(--background-color-rgb), 0) 100%
  );
}

.scroll-right-fade.fade-active.header {
  background: linear-gradient(
    to left,
    rgba(var(--header-background-color-rgb), 1) 0%,
    rgba(var(--header-background-color-rgb), 0) 100%
  );
}

.sticky-top {
  position: sticky;
  top: 0;
  margin-bottom: calc(-1 * var(--fade-overlay-size));
}

.sticky-right {
  position: sticky;
  right: 0;
  margin-left: calc(-1 * var(--fade-overlay-size));
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  margin-top: calc(-1 * var(--fade-overlay-size));
}

.sticky-left {
  position: sticky;
  left: 0;
  margin-right: calc(-1 * var(--fade-overlay-size));
}
