.restaurant-menu-item-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: inset(0px 0px);
}

.restaurant-menu-item-video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  margin: -4px;
  object-fit: cover;
  filter: blur(4px);
}

.restaurant-menu-item-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /** Don't know why, necessary to prevent blink on iOS. */
  background-color: rgba(255, 255, 255, 0.01);
}

.video-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1313134d;
  border-radius: 7px;
}

.video-overlay-loading-icon {
  width: 24px;
  height: 24px;
  padding: 8px;
  display: block;
  color: var(--video-loading-indicator-color);
}

.video-error-overlay {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 20px;
  color: var(--video-overlay-color);
}

.video-error-overlay-icon {
  width: 80px;
  height: 80px;
  padding-bottom: 8px;
}

.video-error-overlay-text {
  font-size: 14px;
}

.video-error-overlay-button {
  font-size: 16px;
  font-weight: bold;
}
