/* Container */

.restaurant-menu-page-container {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--background-color);
}

.restaurant-menu-top-stick-container {
  position: sticky;
  top: 0;
  /* On top of scroll fade. */
  z-index: 2;
  background-color: var(--header-background-color);
}

.restaurant-menu-page-container > .scrolling-menu-bar-container {
  /* Prevent the bar from shrinking vertically. */
  flex-shrink: 0;
}

.restaurant-menu-section-list-container {
  position: relative;
  flex-grow: 1;
}

.restaurant-menu-page-container .restaurant-menu-item-list {
  /**
   * Improves but does not fix completely the bug where the list can be
   * scrolled vertically while swiping horizontally between sections.
   */
  touch-action: pan-y pinch-zoom;
}

.restaurant-menu-item-list-single-container {
  height: 100%;
}

.restaurant-menu-section-infinite-list-container {
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 0 22px 24px 22px;
}

/* KEEP IN SYNC WITH RestaurantSearchPage.css and RestaurantMenuHeader.css */
@media only screen and (min-width: 480px) {
  .restaurant-menu-section-container {
    max-width: 436px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-section-container {
    max-width: 724px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .restaurant-menu-section-container {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
}

.restaurant-menu-section-container::after {
  content: "";
  display: block;
  width: calc(100% + 44px);
  margin-left: -22px;
  height: 8px;
  background-color: var(--surface-color);
  border-color: var(--border-color);
  border-width: 1px;
  border-style: solid none;
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-section-container::after {
    display: none;
  }
}

/* Modal */

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
