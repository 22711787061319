.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-no-tap-dismiss {
  pointer-events: none;
}

.overlay-tap-dismiss {
  pointer-events: auto;
}

.overlay-content-container {
  position: relative;
  background-color: #1313134d;
  border-radius: 7px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 11px;
  padding: 28px 36px 28px 36px;
  margin: 30px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .overlay-content-container > *:not(:last-child) {
    margin-bottom: 11px;
  }
}

.overlay-close-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 16px 0 0;
  font-size: 25px;
  line-height: 25px;
  font-weight: bold;
}

.overlay-icon {
  width: 25px;
  height: 25px;
}

.overlay-text {
  font-size: 20px;
  text-align: center;
}

.overlay-fade-in-enter {
  opacity: 0;
}

.overlay-fade-in-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.overlay-fade-in-enter-done {
  opacity: 1;
}

.overlay-fade-in-exit {
  opacity: 1;
  pointer-events: none;
}

.overlay-fade-in-exit-active {
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms;
}

.overlay-fade-in-exit-done {
  opacity: 0;
  pointer-events: none;
}

.full-screen-overlay {
  background-color: #0e0e0e99;
}

.full-screen-overlay-content-container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 30px 68px;
}

.full-screen-overlay-title {
  font-size: 22px;
  line-height: 24px;
  /* Because some fonts don't have semibold. */
  font-weight: var(--price-weight);
  text-align: center;
  padding-top: 24px;
}

.full-screen-overlay-subtitle {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding-top: 12px;
}

.full-screen-overlay-icon-container {
  width: 64px;
  height: 64px;
}
