.restaurant-menu-item-swipe-up-promo-container {
  display: flex;
  flex-flow: row nowrap;
}

.restaurant-menu-item-swipe-up-promo-up-down-arrow-container {
  display: flex;
  flex-flow: column nowrap;
}

.restaurant-menu-item-swipe-up-promo-hand {
  padding-top: 31.5px;
  padding-left: 12px;
}

.restaurant-menu-item-swipe-up-promo-arrow-up {
}

.restaurant-menu-item-swipe-up-promo-arrow-down {
  padding-top: 11.5px;
}

.restaurant-menu-item-swipe-left-promo-container {
  display: flex;
  flex-flow: column nowrap;
}

.restaurant-menu-item-swipe-up-promo-left-right-arrow-container {
  display: flex;
  flex-flow: row nowrap;
}

.restaurant-menu-item-swipe-left-promo-hand {
  padding-left: 18.75px;
}

.restaurant-menu-item-swipe-left-promo-arrow-left {
}

.restaurant-menu-item-swipe-left-promo-arrow-right {
  padding-left: 11.5px;
}
