.item-video-icon {
  box-sizing: border-box;
  width: 26px;
  height: 26px;
  padding: 0 0 0 2px;
  background-color: var(--play-button-background-color);
  color: var(--play-button-fill-color);
  border: 2px solid var(--background-color);
  border-radius: 50%;
  flex-shrink: 0;
  display: block;
}

.item-video-icon-elevated {
  /* Positioned elements are always above non-positioned elements. */
  position: relative;
  /* On top of promo background. */
  z-index: 4;
}

.restaurant-menu-item-video-icon-container-expanded:after {
  content: "";
  display: block;
  position: absolute;
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: -20px;
  z-index: 4;
}
