.cart-header-container {
  padding: 17px 20px 0px 20px;
  margin: 0;
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .cart-header-container > *:not(:last-child) {
    margin-right: 12px;
  }
}

.cart-header {
}

.cart-header-amount {
  padding: 2px 8px;
  background-color: var(--item-description-color);
  color: var(--background-color);
  border-radius: 7px;
  font-size: 16px;
  font-weight: bold;
}

.cart-item-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: calc(100% - 32px);
  padding: 22px 20px 18px 20px;
  gap: 8px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .cart-item-container > *:not(:last-child) {
    margin-right: 8px;
  }
}

.cart-item-title-container {
  text-align: left;
  font-size: 16px;
}

.cart-item-title {
  display: inline-block;
}

.cart-item-price {
  padding-left: 12px;
  display: inline-block;
}

.cart-item-quantity {
  font-size: 14px;
  font-weight: bold;
}

.quantity-container {
  align-items: center;
  gap: 8px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .quantity-container > *:not(:last-child) {
    margin-right: 8px;
  }
}

.cart-total-divider {
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 58px;
  height: 1px;
  background-color: #484848;
}

.cart-total-container {
  padding: 20px 20px 80px 20px;
  text-align: left;
}

.cart-total-title-container {
  font-size: 16px;
}

.cart-total-title-container > * {
  display: inline-block;
}

.cart-total-amount {
  padding-left: 12px;
}

.cart-total-subtitle {
  font-size: 14px;
}

.cart-no-items-text {
  padding: 12px 20px 12px 20px;
}
