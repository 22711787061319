.restaurant-menu-reach-out-container {
  padding: 21px 0 47px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: 19px;
}

@media only screen and (min-width: 480px) {
  .restaurant-menu-reach-out-container {
    max-width: 436px;
    margin-left: auto;
    margin-right: auto;
  }
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-reach-out-container > *:not(:last-child) {
    margin-bottom: 19px;
  }
}

.restaurant-menu-reach-out-text {
  font-size: 14px;
  text-align: center;
  color: var(--item-description-color);
}

.restaurant-menu-reach-out-button {
  font-size: 14px;
  font-weight: bold;
  padding: 14px 0 14px 0;
  border-radius: 7px;
  margin: 0 53px 0 53px;
  text-align: center;
  background-color: var(--surface-color);
  color: var(--item-description-color);
}
