.restaurant-menu-item-footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.restaurant-menu-item-footer-background {
  position: absolute;
  bottom: 0;
  height: 215px;
  width: 100%;
  background: linear-gradient(
    to top,
    var(--video-fade-color),
    #00000000 89.08%
  );
}

.restaurant-menu-item-footer {
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  color: var(--video-item-text-color);
}

.restaurant-menu-item-footer-text-container {
  z-index: 1; /* On top of background. */
  flex-grow: 1;
  padding: 0 22px 6px 22px;
}

.restaurant-menu-item-footer-text-container
  .restaurant-menu-item-nonvideo-row::after {
  display: none;
}

.restaurant-menu-item-footer-title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-item-footer-title-container > *:not(:last-child) {
    margin-right: 16px;
  }
}

.restaurant-menu-item-footer-title {
  font-size: 18px;
  font-weight: bold;
  flex-grow: 1;
}

.restaurant-menu-item-footer-subtitle {
  font-size: 14px;
  text-transform: lowercase;
  /* Account for increased line-height. */
  margin-top: 9.2px;
  margin-bottom: -2.8px;
  line-height: 1.4;
}

.restaurant-menu-item-footer-price {
  font-size: 18px;
  font-weight: bold;
}
