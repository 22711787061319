.restaurant-menu-item-list {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
}

.restaurant-menu-subsection-items-container {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-subsection-items-container {
    flex-flow: row wrap;
    position: relative;
    gap: 8px;
    margin: 16px 0;
  }

  .restaurant-menu-subsection-items-container > * {
    flex-basis: calc(50% - 4px);
  }

  .restaurant-menu-subsection-items-container
    > .restaurant-menu-page-empty-text {
    flex-basis: 100%;
  }
}

.restaurant-menu-page-empty-text {
  text-align: center;
  padding-top: 120px;
  font-size: 20px;
  color: var(--item-description-color);
}
