.restaurant-menu-item-nonvideo-row {
  position: relative;
  padding: 16px 0;
  color: var(--nonvideo-items-text-color);
}

.restaurant-menu-item-nonvideo-row::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--nonvideo-items-divider-color);
}

.restaurant-menu-item-nonvideo-title-container {
  display: flex;
  flex-flow: row nowrap;
  font-size: 18px;
  font-weight: var(--header-weight);
  line-height: 20px;
  gap: 8px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-item-nonvideo-title-container > *:not(:last-child) {
    margin-right: 8px;
  }
}

.restaurant-menu-item-nonvideo-title {
  flex-grow: 1;
}

.restaurant-menu-item-nonvideo-price {
  width: 68px;
  text-align: right;
}

.restaurant-menu-item-nonvideo-subtitle-container {
  margin-top: 12px;
  display: flex;
  flex-flow: row nowrap;
  gap: 34px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-item-nonvideo-subtitle-container > *:not(:last-child) {
    margin-right: 34px;
  }
}

.restaurant-menu-item-nonvideo-subtitle {
  font-size: 14px;
  line-height: 20px;
  flex-grow: 1;
}

.restaurant-menu-item-nonvideo-addon-button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffffbf;
  color: #272727;
  box-sizing: border-box;
  padding: 4px;
  display: block;
}
