.error-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.error-title {
  text-align: center;
  padding: 24px;
  color: var(--section-title-color);
}

.error-message {
  color: var(--item-description-color);
}

.error-details-label {
  text-align: center;
  color: var(--item-description-color);
}

.error-details {
  text-align: center;
  color: var(--item-description-color);
}

.error-reload-button {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-top: 12px;
  color: var(--section-title-color);
}
