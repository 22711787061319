.vertical-scrolling-container {
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /** Attempt to allow sideways swipes to be handled. */
  touch-action: pan-y pinch-zoom;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.vertical-scrolling-container::-webkit-scrollbar {
  display: none;
}
