.bottom-sheet-select {
  font-size: 1.25em;
  padding: 4px 27px 4px 27px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 18px;
  justify-content: space-between;
  color: var(--item-title-color);
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .bottom-sheet-select > *:not(:last-child) {
    margin-right: 18px;
  }
}

.bottom-sheet-select-icon-container {
  position: relative;
  width: 15px;
  height: 15px;
}

.bottom-sheet-select-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid var(--item-title-color);
  box-sizing: border-box;
}

.bottom-sheet-select-selected-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 3px;
  background-color: var(--item-title-color);
}
