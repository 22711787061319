.restaurant-search-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.restaurant-search-header-container {
  width: calc(100% - 24px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 38px 12px 12px 12px;
  position: sticky;
  top: 0;
  background-color: var(--background-color);
  /** On top of item list. */
  z-index: 1;
}

.restaurant-search-bar-container {
  flex-grow: 1;
}

.restaurant-search-close {
  padding: 12px;
  margin-left: 12px;
  font-size: 14px;
  color: var(--section-title-color);
}

.restaurant-search-item-container {
  /** Clip to flex size so overflow scrolls. */
  overflow: clip;
  /** Ensure fade is relative to container. */
  position: relative;
  /** Ensure it takes the whole space to prevent fade from showing when not full height. */
  flex-grow: 1;
}

/* KEEP IN SYNC WITH RestaurantMenuPage.tsx */
@media only screen and (min-width: 480px) {
  .restaurant-search-item-container {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .restaurant-search-header-container {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) {
  .restaurant-search-item-container {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }

  .restaurant-search-header-container {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .restaurant-search-item-container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }

  .restaurant-search-header-container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}

.zero-height {
  /** For some reason, setting a height is necessary? */
  height: 0;
}
