.restaurant-menu-item-page {
  touch-action: pinch-zoom;
  background-color: var(--desktop-margin-background-color);
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-item-container {
    position: relative;
    max-width: calc(var(--full-height, 100%) * 9 / 16);
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
}

.restaurant-menu-item-page .restaurant-menu-item-list {
  touch-action: pinch-zoom;
}

.restaurant-menu-item-items-container {
  padding-top: 66px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--nonvideo-items-background-color);
}

.restaurant-menu-item-items-container .restaurant-menu-item-row-title {
  color: var(--nonvideo-items-text-color);
}

.restaurant-menu-item-items-container .restaurant-menu-item-row-price {
  color: var(--nonvideo-items-text-color);
}

.restaurant-menu-item-items-container .restaurant-menu-item-row-subtitle {
  color: var(--nonvideo-items-text-color);
}

.restaurant-menu-item-items-container
  .restaurant-menu-item-row-button:not(:last-child)::after {
  background-color: var(--nonvideo-items-divider-color);
}

.restaurant-menu-item-items-container .restaurant-menu-item-list {
  margin: 0 22px;
}

.restaurant-menu-item-items-header {
  font-size: 22px;
  font-weight: var(--header-weight);
  line-height: 30px;
  color: var(--nonvideo-items-text-color);
  padding: 0px 22px;
  letter-spacing: -0.02em;
}

.restaurant-menu-item-header-background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 141px;
  background: linear-gradient(var(--video-fade-color), #00000000 61.11%);
  pointer-events: none;
}

.restaurant-menu-item-header-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  pointer-events: all;

  color: var(--video-section-titles-color);
}

.restaurant-menu-item-header-container .scrolling-menu-bar {
  color: var(--video-section-titles-color);
}

.restaurant-menu-item-header-container .scrolling-menu-bar-indicator {
  background-color: var(--video-section-titles-color);
}

.restaurant-menu-icon-back-button {
  width: 24px;
  height: 24px;
  padding: 8px;
  margin-left: 10px;
  vertical-align: middle;
}

.restaurant-menu-item-search-heading {
  font-size: 20px;
  font-weight: bold;
  padding: 14px 28px 14px 28px;
}

.restaurant-menu-item-footer-cart-button-container {
}
