.scrolling-menu-bar-container-container {
  position: relative;
}

/* KEEP IN SYNC WITH RestaurantSearchPage.tsx */
@media only screen and (min-width: 480px) {
  .restaurant-menu-page-container .scrolling-menu-bar-container-container {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-page-container .scrolling-menu-bar-container-container {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .restaurant-menu-page-container .scrolling-menu-bar-container-container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}

.scrolling-menu-bar-container {
  overflow-x: auto;
  position: relative;
  padding: 10px 0px 10px 0px;
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrolling-menu-bar-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrolling-menu-bar-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrolling-menu-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  gap: 20px;
  color: var(--section-bar-text-color);
  width: fit-content;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .scrolling-menu-bar > *:not(:last-child) {
    margin-right: 20px;
  }
}

.scrolling-menu-bar-button {
  font-size: 18px;
  padding: 4px;
  white-space: nowrap;
}

.scrolling-menu-bar-button-container:first-child {
  padding-left: 24px;
}

.scrolling-menu-bar-button-container:last-child {
  padding-right: 24px;
}

.scrolling-menu-bar-button.selected {
  font-weight: bold;
}

.scrolling-menu-bar-indicator {
  position: absolute;
  bottom: 8px;
  height: 3px;
  border-radius: 1.5px;
  background-color: var(--section-bar-text-color);
}
