.restaurant-menu-item-footer-cart-button {
  width: 37px;
  height: 37px;
  border-radius: 7px;
  background-color: var(--background-color);
  margin-left: auto;
  margin-right: 28px;
  margin-bottom: 28px;
  position: relative;
}

.restaurant-menu-item-footer-cart-button-icon {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.restaurant-menu-item-footer-cart-button-badge {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: var(--item-description-color);
  color: var(--background-color);
  position: absolute;
  top: -10px;
  right: -7px;
  font-size: 14px;
  text-align: center;
}
