.restaurant-menu-item-scroll-indicator-container {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-item-scroll-indicator-container > *:not(:last-child) {
    margin-bottom: 8px;
  }
}

.restaurant-menu-item-scroll-indicator-dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid var(--video-scroll-indicator-color);
}

.restaurant-menu-item-scroll-indicator-selected {
  background-color: var(--video-scroll-indicator-color);
}
