.restaurant-menu-selector-modal-menu-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 2px;
  margin-bottom: 46px;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-selector-modal-menu-container > *:not(:last-child) {
    margin-right: 2px;
  }
}
