/* Shared variables */

:root {
  /* Dynamic colors */
  --background-color-rgb: 255, 255, 255; /* #ffffff */
  --background-color: rgb(var(--background-color-rgb));
  --surface-color-rgb: 232, 232, 232; /* #e8e8e8 */
  --surface-color: rgb(var(--surface-color-rgb));
  --border-color-rgb: 234, 234, 234; /* #eaeaea */
  --border-color: rgb(var(--border-color-rgb));
  --header-background-color-rgb: 255, 255, 255; /* #ffffff */
  --header-background-color: rgb(var(--header-background-color-rgb));
  --restaurant-title-color-rgb: 39, 39, 39; /* #272727 */
  --restaurant-title-color: rgb(var(--restaurant-title-color-rgb));
  --search-filter-button-color-rgb: 102, 102, 102; /* #666666 */
  --search-filter-button-color: rgb(var(--search-filter-button-color-rgb));
  --section-bar-text-color-rgb: 102, 102, 102; /* #666666 */
  --section-bar-text-color: rgb(var(--section-bar-text-color-rgb));
  --section-title-color-rgb: 39, 39, 39; /* #272727 */
  --section-title-color: rgb(var(--section-title-color-rgb));
  --subsection-title-color-rgb: 39, 39, 39; /* #272727 */
  --subsection-title-color: rgb(var(--subsection-title-color-rgb));
  --item-title-color-rgb: 39, 39, 39; /* #272727 */
  --item-title-color: rgb(var(--item-title-color-rgb));
  --item-price-color-rgb: 102, 102, 102; /* #666666 */
  --item-price-color: rgb(var(--item-price-color-rgb));
  --item-description-color-rgb: 102, 102, 102; /* #666666 */
  --item-description-color: rgb(var(--item-description-color-rgb));
  --play-button-background-color-rgb: 113, 63, 255; /* #713fff */
  --play-button-background-color: rgb(var(--play-button-background-color-rgb));

  /* Static colors */
  --video-fade-color: rgba(39, 39, 39, 0.75); /* #272727 */
  --video-section-titles-color: #d9d9d9;
  --video-item-text-color: #ffffff;
  --video-scroll-indicator-color: #e8e8e8;
  --video-loading-indicator-color: #e8e8e8;
  --video-overlay-color: white;
  --scrim-color: #000000aa;
  --play-button-fill-color: #f1f1f1;
  --nonvideo-items-background-color: #232323;
  --nonvideo-items-text-color: #ffffff;
  --nonvideo-items-divider-color: #404040;
  --full-screen-overlay-background-color: #272727bf;
  --full-screen-overlay-text-color: white;
  --desktop-margin-background-color: black;

  /* Fonts */
  --font-family: "Manrope", sans-serif;
  --header-weight: bold;
  --price-weight: 600;
}

/* Other global styles */

html {
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  /** Default text to the item description color. ? */
  color: red; /*var(--item-description-color);*/

  line-height: 1;
}

* {
  -webkit-tap-highlight-color: transparent;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  color: var(--item-description-color);
}

button {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  color: inherit;
  font-family: inherit;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

input:focus-visible {
  outline: unset;
}

.full-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--full-height, 100%);
  background-color: var(--background-color);
}

.fill-space {
  width: 100%;
  height: 100%;
}

.single-row-flex-container {
  display: flex;
  flex-flow: row nowrap;
}

.single-column-flex-container {
  display: flex;
  flex-flow: column nowrap;
}

.flex-fill {
  flex-grow: 1;
}

.size-fill {
  width: 100%;
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.render-line-breaks {
  white-space: pre-wrap;
}

.lowercase {
  text-transform: lowercase;
}

.nowrap {
  white-space: nowrap;
}

.hidden {
  display: none;
}

.very-hidden {
  display: none !important;
}

.transparent {
  opacity: 0;
}
