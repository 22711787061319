.restaurant-menu-item-row-cart-button {
  border-radius: 7px;
  background-color: var(--background-color);
  flex-shrink: 0;
}

.restaurant-menu-item-row-cart-button:active {
  background-color: var(--item-description-color);
  color: var(--background-color);
}

.restaurant-menu-item-row-cart-button-small {
  width: 28px;
  height: 28px;
  font-size: 16px;
  line-height: 16px;
  /** A little padding to center the "+". */
  padding-bottom: 2px;
}

.restaurant-menu-item-row-cart-button-large {
  width: 37px;
  height: 37px;
  font-size: 20px;
  line-height: 20px;
  /** A little padding to center the "+". */
  padding-bottom: 2px;
}
