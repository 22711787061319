.restaurant-menu-item-list-footer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 12px;
  color: var(--nonvideo-items-text-color);
}

.restaurant-menu-item-list-footer-text {
  font-size: 20px;
  /* On top of footer background. */
  z-index: 1;
}

.restaurant-menu-item-list-footer-icon-container {
  position: relative;
  width: 34px;
  height: 10px;
}

.restaurant-menu-item-list-footer-icon {
  position: absolute;
  width: 34px;
  height: 34px;
  /** 1/2 height of container - 1/2 height. */
  top: -12px;
}
