.search-bar {
  box-sizing: border-box;
  border-radius: 7px;
  border: 1px solid var(--border-color);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.search-bar-search-icon {
  width: 16px;
  height: 16px;
  padding: 8px;
  position: absolute;
  z-index: -1;
  color: var(--item-description-color);
}

.search-bar-input {
  border: none;
  width: 100%;
  font-size: 12px;
  color: var(--item-description-color);
  background-color: transparent;
  padding: 8px 8px 8px 34px;
  font-family: var(--font-family);
  /* Set font size to 16px to avoid zooming on iOS. */
  font-size: 16px;
}

.search-bar-input::-webkit-search-decoration,
.search-bar-input::-webkit-search-cancel-button,
.search-bar-input::-webkit-search-results-button,
.search-bar-input::-webkit-search-results-decoration {
  display: none;
}

.search-bar-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--item-description-color);
  opacity: 1; /* Firefox */
  font-family: var(--font-family);
  font-size: 12px;
}

.search-bar-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--item-description-color);
  font-family: var(--font-family);
  font-size: 12px;
}

.search-bar-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--item-description-color);
  font-family: var(--font-family);
  font-size: 12px;
}
