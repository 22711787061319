.restaurant-menu-selector {
  background-color: var(--header-background-color);
}

.restaurant-menu-selector-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 6px;
  padding: 0px 25px 14px 25px;
  box-sizing: border-box;
  color: var(--search-filter-button-color);
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-selector-container > *:not(:last-child) {
    margin-right: 11px;
  }
}

/* KEEP IN SYNC WITH RestaurantSearchPage.tsx */
@media only screen and (min-width: 480px) {
  .restaurant-menu-selector-container {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-selector-container {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .restaurant-menu-selector-container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}

.restaurant-menu-selector-title {
  font-size: 20px;
}

.restaurant-menu-selector-chevron {
  padding-top: 5px;
  stroke: var(--search-filter-button-color);
}

.restaurant-menu-selector-separator {
  height: 1px;
  background-color: var(--header-background-color);
}

.restaurant-menu-selector-separator::after {
  display: block;
  content: "";
  background-color: rgba(var(--search-filter-button-color-rgb), calc(77 / 255));
  height: 1px;
  margin: 0;
  box-sizing: border-box;
  margin-left: 14px;
  margin-right: 14px;
}
