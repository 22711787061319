.restaurant-landing-page {
  text-align: center;
  height: 100%;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.restaurant-landing-page::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.restaurant-landing-page {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.restaurant-landing-page-image {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: var(--restaurant-title-color);
  margin-top: 39px;
  margin-left: auto;
  margin-right: auto;
}

.restaurant-landing-page-image-fallback {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: var(--restaurant-title-color);
  margin-top: 39px;
  margin-left: auto;
  margin-right: auto;
}

.restaurant-landing-page-title {
  font-size: 28px;
  margin-top: 5px;
  /** TODO: Avoid this, but we need something that's good on the background for now. */
  color: var(--section-title-color);
}

.restaurant-landing-page-description {
  font-size: 12px;
  margin-top: 10px;
  color: var(--item-description-color);
}

.restaurant-landing-page-blurb {
  margin: 10px 20px 0px 20px;
  font-size: 16px;
  color: var(--item-description-color);
}

@media only screen and (min-width: 768px) {
  .restaurant-landing-page-blurb {
    max-width: 728px;
    margin-left: auto;
    margin-right: auto;
  }
}

.restaurant-landing-page-menu-button-container {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

@media only screen and (min-width: 480px) {
  .restaurant-landing-page-menu-button-container {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .restaurant-landing-page-menu-button-image {
    border-radius: 7px;
  }
}

.restaurant-landing-page-menu-button-image {
  width: 100%;
  aspect-ratio: 10/8;
  object-fit: cover;
}

.restaurant-landing-page-menu-button-image-fallback {
  width: 100%;
  height: 40px;
}

.restaurant-landing-page-menu-button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-color);
  display: inline;
  padding: 8px 14px 8px 14px;
  border-radius: 7px;
  font-size: 18px;
}

.restaurant-landing-page-hours {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  color: var(--item-description-color);
}
