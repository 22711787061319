.animated-route-enter.immediate-transition {
  /* Create a new stacking context. */
  position: relative;
  z-index: 1;
}

.animated-route-enter-active.immediate-transition {
}

.animated-route-exit.immediate-transition {
  /* Create a new stacking context. */
  position: relative;
  z-index: -1;
}

.animated-route-exit-active.immediate-transition {
}
