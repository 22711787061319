.restaurant-menu-item-details-page {
  padding: 38px 14px 40px 14px;
}

.restaurant-menu-item-details-title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 12px;
  color: var(--item-title-color);
}

.restaurant-menu-item-details-subtitle {
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 26px;
  color: var(--item-description-color);
  line-height: 1.4;
}

.restaurant-menu-item-details-add-text {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 8px;
  color: var(--item-title-color);
}

.restaurant-menu-item-details-addon-text {
  font-size: 16px;
  font-weight: normal;
  color: var(--item-description-color);
  line-height: 1.4;
}
