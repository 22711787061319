.restaurant-filters-header-container {
  width: calc(100% - 24px);
  display: flex;
  flex-flow: row nowrap;
  padding: 38px 12px 0px 12px;
  align-items: center;
}

.restaurant-filters-title {
  flex-grow: 1;
  font-size: 22px;
  padding-left: 10px;
  /* TODO: Also update. */
  color: var(--section-title-color);
}

.restaurant-filters-close {
  padding: 12px;
  margin-left: 12px;
  font-size: 14px;
  color: var(--section-title-color);
}

.restaurant-filters-container {
  display: flex;
  flex-flow: row wrap;
  padding: 16px;
  gap: 12px;
}

@media only screen and (min-width: 768px) {
  .restaurant-filters-container {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }

  .restaurant-filters-header-container {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-filters-container > *:not(:last-child) {
    margin-right: 12px;
  }
}

.restaurant-filters-container > * {
  flex-basis: calc(50% - 6px);
}
