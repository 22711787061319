.bottom-sheet-modal {
  bottom: 0;
  width: 100%;
  max-width: 768px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  border-radius: 23px 23px 0 0;
  background-color: var(--surface-color);
  /** On top of scrim. */
  z-index: 3;
}

.bottom-sheet-modal-expanded {
  position: fixed;
}

.bottom-sheet-modal-grab-bar {
  width: 47px;
  height: 2px;
  /** TODO: Find a better solution. */
  background-color: var(--item-description-color);
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 1px;
}

.bottom-sheet-slide-up-enter {
  position: fixed;
  transform: translate(-50%, 100%);
}

.bottom-sheet-slide-up-enter-active {
  position: fixed;
  transform: translate(-50%, 0);
  transition: transform 500ms;
}

.bottom-sheet-slide-up-exit {
  position: fixed;
  transform: translate(-50%, 0);
}

.bottom-sheet-slide-up-exit-active {
  position: fixed;
  transform: translate(-50%, 100%);
  transition: transform 500ms;
}

.bottom-sheet-scrim {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--scrim-color);
  /** On top of scroll fade. */
  z-index: 2;
}

.bottom-sheet-section {
  padding: 16px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
}

.bottom-sheet-section-header {
  text-align: left;
}

.bottom-sheet-option {
  text-align: left;
  font-size: 1.25em;
  padding: 4px 0 4px 0;
}
