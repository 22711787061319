.restaurant-menu-section-header {
  font-size: 22px;
  font-weight: var(--header-weight);
  padding: 24px 0 0 0;
  color: var(--section-title-color);
}

.no-bottom-padding {
  padding-bottom: 0;
}
