.restaurant-menu-header-container {
  background-color: var(--header-background-color);
}

.restaurant-menu-header {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 4px;
  box-sizing: border-box;
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-header > *:not(:last-child) {
    margin-bottom: 4px;
  }
}

/* KEEP IN SYNC WITH RestaurantSearchPage.tsx */
@media only screen and (min-width: 480px) {
  .restaurant-menu-header {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) {
  .restaurant-menu-header {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .restaurant-menu-header {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}

.restaurant-menu-restaurant-images-container {
  position: relative;
  min-height: 113px;
}

.restaurant-menu-header-preview-image {
  width: 100%;
  aspect-ratio: 375 / 172;
  -webkit-clip-path: polygon(0 0, 0 40%, 100% 40%, 100% 0);
  clip-path: polygon(0 0, 0 67%, 100% 100%, 100% 0);
  object-fit: cover;
  max-height: 200px;
}

@supports not (aspect-ratio: auto) {
  .restaurant-menu-header-preview-image {
    max-height: 172px;
  }
}

.restaurant-menu-header-image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: var(--restaurant-title-color);
  position: absolute;
  left: 25px;
  bottom: 9px;
  border: 0.5px solid var(--item-description-color);
}

.restaurant-menu-header-image-fallback {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: var(--restaurant-title-color);
  position: absolute;
  left: 25px;
  bottom: 9px;
}

.restaurant-menu-header-title-row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  padding: 0 25px 12px 25px;
  box-sizing: border-box;
}

.restaurant-menu-header-title {
  font-size: 30px;
  font-weight: normal;
  color: var(--restaurant-title-color);
}

.restaurant-menu-header-actions {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  flex-shrink: 0;
  color: var(--search-filter-button-color);
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .restaurant-menu-header-actions > *:not(:last-child) {
    margin-right: 4px;
  }
}

.restaurant-menu-header-action {
  position: relative;
}

.restaurant-menu-header-action-separator {
  height: 32px;
  width: 1px;
  background-color: var(--search-filter-button-color);
}

.restaurant-menu-header-search-icon {
  width: 16px;
  height: 16px;
  padding: 8px;
  vertical-align: middle;
}

.restaurant-menu-header-filter-icon {
  width: 20px;
  height: 20px;
  padding: 6px;
  vertical-align: middle;
}

.restaurant-menu-header-filter-indicator {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--search-filter-button-color);
  position: absolute;
  top: 5px;
  right: 5px;
}
