.restaurant-filters-item {
  background-color: var(--surface-color);
  border-radius: 7px;
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  /* Fill width when forced to wrap. */
  flex-grow: 1;
}

.restaurant-filters-item-title {
  padding: 12px;
  font-size: 17px;
  flex-grow: 1;
  color: var(--item-description-color);
}

.restaurant-filters-item-checkbox {
  width: 28px;
  height: 28px;
  background-color: var(--background-color);
  border-radius: 7px;
  border: 1px solid var(--border-color);
  margin-right: 12px;
  text-align: center;
}

.restaurant-filters-item-checkbox-checked {
  background-color: var(--item-description-color);
}

.restaurant-filters-item-checkbox-icon {
  color: var(--background-color);
  width: 20px;
  height: 20px;
  padding: 4px;
}
